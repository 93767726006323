import { useContext } from 'react'

import TrackingContext from '../context/TrackingContext'
import { makeGraphQLRequest } from '../../utils/graphqlClientSide'

const useSubmitForm = (apolloServerUrl: string) => {
    const { events, setEvents } = useContext(TrackingContext)

    const sendForm = async (
        fn: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        query: any,
        variables: Record<string, unknown>,
        trackingData: Record<string, unknown>
    ) => {
        let success = true
        let errors = null

        const response = await makeGraphQLRequest(
            apolloServerUrl,
            query,
            variables,
            fn
        )

        if (!response.success || response.errors) {
            success = false
            if (response.errors) {
                errors = {}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                response.errors.forEach((error: any) => {
                    // Extract field name from GraphQL validation error message
                    const match = error.message.match(
                        /Field "([^"]+)" is not defined/
                    )
                    if (match) {
                        errors[match[1]] = 'Invalid field'
                    }
                })
                console.error(response.errors)
            }
            return [success, errors || {}]
        }

        if (response.success) {
            setEvents([
                ...events,
                {
                    category: 'form',
                    actionType: 'submission',
                    data: trackingData,
                },
            ])
        } else {
            success = false
            errors = []
            for (let i = 0; i < response.errors.length; i++) {
                if (response.errors[i].name === 'hubspotErr') {
                    const fieldName =
                        response.errors[i].msg.match(/fields\.(.*)'\. (.*)/)
                    if (fieldName && fieldName[1] && fieldName[2]) {
                        errors.push({
                            [fieldName[1]]: fieldName[2],
                        })
                    }
                } else {
                    errors.push({
                        [response.errors[i].name]: response.errors[i].msg,
                    })
                }
            }
        }

        return [success, errors]
    }

    return [sendForm]
}

export default useSubmitForm
