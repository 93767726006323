import ScrollDepthEvent from './scroll-depth'

export const initTracking = (
    assetType: string,
    additionalContent?: object,
    pageTrackingEvent?: object
) => {
    if (typeof assetType !== 'string') {
        throw new Error('Tracking expects a content asset type as a string')
    }

    if (
        typeof additionalContent !== 'object' &&
        additionalContent !== undefined
    ) {
        throw new Error('Tracking expects additionalContent to be an object')
    }

    const config = {
        server: 'https://spoor-api.ft.com/px.gif?org=fdi-intelligence',
        context: {
            product: 'fdi-intelligence',
            content: {
                asset_type: assetType,
                ...additionalContent,
            },
        },
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const oTracking = require('@financial-times/o-tracking/browser')
    oTracking.init(config)
    oTracking.click.init('cta')
    ScrollDepthEvent.init()
    oTracking.page(pageTrackingEvent)

    return true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fireEvent = (detail: any, bubbles = false) => {
    return document.body.dispatchEvent(
        new CustomEvent('oTracking.event', {
            detail,
            bubbles,
        })
    )
}
