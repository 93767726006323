export const navItems = [
    {
        name: 'Home',
        route: '/',
    },
    {
        name: 'Data Trends',
        route: '/data-trends',
        items: [
            {
                name: 'World',
                route: '/data-trends',
            },
            {
                name: 'Americas',
                route: '/data-trends/view-from/americas',
            },
            {
                name: 'Europe',
                route: '/data-trends/view-from/europe',
            },
            {
                name: 'Asia-Pacific',
                route: '/data-trends/view-from/asia-pacific',
            },
            {
                name: 'MEA',
                route: '/data-trends/view-from/middle-east-africa',
            },
        ],
    },
    {
        name: 'Opinion',
        route: '/opinion',
        items: [
            {
                name: 'The editor',
                route: '/opinion/inside-fdi',
                capitalize: true,
            },
            {
                name: 'The global lawyer',
                route: '/opinion/the-global-lawyer',
                capitalize: true,
            },
            {
                name: 'Regional perspectives',
                route: '/opinion/view-from',
            },
            {
                name: 'Free Zone Focus',
                route: '/opinion/free-zone-focus',
                capitalize: true,
            },
        ],
    },
    {
        name: 'FDI analysis',
        route: '/stream/news',
        items: [
            {
                name: 'News',
                route: '/stream/news',
            },
            {
                name: 'Features',
                route: '/stream/feature',
            },
            {
                name: 'Interviews',
                route: '/stream/interview',
            },
            {
                name: 'Videos',
                route: '/stream/video',
            },
            {
                name: 'Partner Content',
                route: '/stream/partner-content',
            },
        ],
    },
    {
        name: 'Rankings & awards',
        route: '/rankings-and-awards',
        items: [
            {
                name: 'European Cities and Regions of the Future',
                route: '/special-report/european-cities-and-regions-of-the-future-202324-83493',
            },
            {
                name: 'Global Free Zones of the Year',
                route: '/content/rankings-and-awards/global-free-zones-of-the-year-2024-awards-84149',
            },
            {
                name: 'Greenfield FDI Performance index',
                route: '/content/data-trends/costa-rica-again-tops-list-of-fdi-overachievers-82539',
            },
        ],
    },
    {
        name: 'Special reports',
        route: '/reports-and-whitepapers',
    },
    {
        name: 'Magazine',
        route: '/stream/Archive',
    },
    {
        name: 'Products & Services',
        route: '/products-and-services',
        desktopLast: true,
        breadcrumbMode: true,
        items: [
            {
                name: 'Products & Services',
                route: '/products-and-services',
                target: '_blank',
            },
            {
                name: 'fDi Markets',
                route: '/products-and-services/fdi-markets',
                target: '_blank',
            },
            {
                name: 'fDi Benchmark',
                route: '/products-and-services/fdi-benchmark',
                target: '_blank',
            },
            {
                name: 'fDi Insights',
                route: 'https://www.fdiinsights.com/',
                target: '_blank',
            },
            {
                name: 'InvestmentFlow',
                route: '/products-and-services/investment-flow',
                target: '_blank',
            },
            {
                name: 'IncentivesFlow',
                route: '/products-and-services/incentives-flow',
                target: '_blank',
            },
            {
                name: 'InvestmentMap',
                route: '/products-and-services/investment-map',
                target: '_blank',
            },
            {
                name: 'fDi Influencers',
                route: '/products-and-services/fdi-influencers',
            },
            {
                name: 'fDi Intelligence',
                route: '/',
            },
            {
                name: 'ZoomProspector',
                route: 'https://www.gisplanning.com/products/zoomprospector/overview',
                target: '_blank',
            },
            {
                name: 'ZoomTour',
                route: 'https://www.gisplanning.com/products/zoomtour/overview',
                target: '_blank',
            },
            {
                name: 'fDi Strategies',
                route: '/products-and-services/fdi-strategies',
                target: '_blank',
            },
            {
                name: 'Events and Roundtable',
                route: '/products-and-services/events-and-roundtables',
                target: '_blank',
            },
            {
                name: 'Advertising Opportunities',
                route: '/products-and-services/advertising-opportunities',
                target: '_blank',
            },
            {
                name: 'Training Workshops',
                route: '/products-and-services/fdi-institute',
                target: '_blank',
            },
            {
                name: 'Amplify',
                route: '/products-and-services/amplify',
                target: '_blank',
            },
            {
                name: 'fDi Institute',
                route: '/products-and-services/fdi-institute',
                target: '_blank',
            },
        ],
    },
    {
        name: 'My account',
        route: '/my-account/account-information',
        desktopHidden: true,
    },
    {
        name: 'Log in or register',
        route: '/user/login',
        desktopHidden: true,
    },
    {
        name: 'Log out',
        route: '/user/logout',
        desktopHidden: true,
    },
    {
        name: 'Contact Us',
        route: '/form/contact-us/General%20Enquiry',
        desktopHidden: true,
    },
]
