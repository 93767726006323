import React from 'react'
import { MobileSearchTriggerProps } from '../../../types'
const MobileSearchTrigger = ({
    isSearchOpen = false,
    openSearch,
    closeSearch,
}: MobileSearchTriggerProps) => {
    const onClick = () => {
        if (isSearchOpen) {
            closeSearch()
        } else {
            openSearch()
        }
    }

    return (
        <div className='mobile-search-trigger'>
            <button className='mobile-search-trigger__button' onClick={onClick}>
                <i className='icon o-icons-icon o-icons-icon--search' />
                <span className='mobile-search-trigger__text'>Search</span>
            </button>
        </div>
    )
}

export default MobileSearchTrigger
