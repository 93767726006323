import React from 'react'

export interface OFormsWrapperProps {
    name: string
    label: string
    errorText?: boolean | string
    optional?: boolean
    promptText?: string
    wide?: boolean
    children: React.ReactNode
    required?: boolean
    error?: boolean | string
}

const OFormsWrapper: React.FC<OFormsWrapperProps> = ({
    name,
    errorText,
    label,
    optional = false,
    promptText = '',
    wide = false,
    children,
    required = false,
    error = false,
}) => {
    const labelClasses = [
        'o-forms__label',
        optional ? 'o-forms__label--optional' : '',
    ]
        .filter(Boolean)
        .join(' ')

    const wrapperClasses = [
        'o-forms',
        wide ? 'o-forms--wide' : '',
        error ? 'o-forms--error' : '',
    ]
        .filter(Boolean)
        .join(' ')

    return (
        <div className={wrapperClasses}>
            <label
                aria-labelledby={promptText ? `${name}-info` : undefined}
                htmlFor={name}
                className={labelClasses}
            >
                {label}
            </label>
            {promptText && (
                <div id={`${name}-info`} className='o-forms__additional-info'>
                    {promptText}
                </div>
            )}
            {children}
            {required && errorText && (
                <div className='o-forms__errortext' aria-live='assertive'>
                    {error ||
                        (typeof errorText === 'string'
                            ? errorText
                            : `Please enter ${label.toLowerCase()}`)}
                </div>
            )}
        </div>
    )
}

export default OFormsWrapper
