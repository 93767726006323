import React from 'react'
import { FieldProps, FormikProps } from 'formik'
import OFormsWrapper, {
    OFormsWrapperProps,
} from '../o-forms-wrapper/OFormsWrapper'

export interface InputProps {
    label: string
    errorText?: string | boolean
    optional?: boolean
    promptText?: string
    disabled?: boolean
    wide?: boolean
    small?: boolean
    required?: boolean
    field: FieldProps['field']
    form: FormikProps<any> | string | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Input: React.FC<InputProps> = ({
    label,
    errorText = '',
    required = false,
    optional = false,
    promptText = '',
    wide = false,
    small = false,
    field,
    form,
    ...otherProps
}) => {
    const name = field.name

    const wrapperProps = {
        name,
        label,
        errorText,
        optional,
        promptText,
        wide,
        required,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (form as FormikProps<any>).errors[name] || false,
    }

    const inputClasses = ['o-forms__text', small ? 'o-forms__text--small' : '']
        .filter(Boolean)
        .join(' ')

    return (
        <OFormsWrapper {...(wrapperProps as OFormsWrapperProps)}>
            <input
                id={name}
                className={inputClasses}
                required={required}
                {...field}
                {...otherProps}
            />
        </OFormsWrapper>
    )
}

export default Input
