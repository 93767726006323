import { print } from 'graphql'

export const makeGraphQLRequest = async (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any,
    variables: Record<string, unknown>,
    fn: string
) => {
    try {
        const req = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: print(query),
                variables,
            }),
        })

        const response = await req.json()

        const data = response.data[fn]

        if (!data.success || data.errors) {
            console.error('GraphQL request failed:', data.errors)
            return {
                success: false,
                errors: data.errors || [],
                data: null,
            }
        }

        return {
            success: true,
            errors: null,
            data: data,
        }
    } catch (error) {
        console.error('Error making GraphQL request:', error)
        return {
            success: false,
            errors: [error],
            data: null,
        }
    }
}
