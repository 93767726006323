import React from 'react'
import { FieldProps } from 'formik'

export interface RadioButtonProps {
    label: string
    value: string
    required?: boolean
    errorText?: boolean | string
    promptText?: string
    disabled?: boolean
    field: FieldProps['field']
    form: {
        touched: {
            [key: string]: boolean
        }
    }
}

const RadioButton: React.FC<RadioButtonProps> = ({
    label,
    field,
    form: { touched },
    value,
    disabled = false,
    ...otherProps
}) => {
    const inputClasses = [
        'o-forms__radio-button',
        touched[field.name] ? 'o-forms__radio-button--touched' : '',
    ]
        .filter(Boolean)
        .join(' ')

    return (
        <>
            <input
                id={`${field.name}_${value}`}
                type='radio'
                name={field.name}
                value={value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                className={inputClasses}
                defaultChecked={field.value === value}
                disabled={disabled}
                {...otherProps}
            />
            <label
                htmlFor={`${field.name}_${value}`}
                className='o-forms__label'
            >
                {label}
            </label>
        </>
    )
}

export default RadioButton
