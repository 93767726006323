/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react'
import { WedgeProps } from './Wedge.props'

const Wedge = (props: WedgeProps) => {
    const [blockHeight, setBlockHeight] = useState<string | number>('auto')
    const [fadePercent, setFadePercent] = useState<string>('67%')
    const childrenContainer = useRef<HTMLDivElement>(null)

    const isReverse = props.options.renderOrder === 'media'
    const backgroundAlign =
        props.options.renderOrder === 'text' ? 'right' : 'left'
    const backgroundSize = props.styling.fullWidthBackground
        ? 'cover'
        : props.styling.backgroundSize
    const customFade = props.styling.customFade || ['45%', '60%']

    useEffect(() => {
        const handleResize = () => {
            const contentBlock = childrenContainer.current
            const contentBlockHeight = contentBlock?.clientHeight || 0

            if (props.styling.backgroundImage && contentBlock) {
                const width = window.innerWidth
                if (width < 980 && width >= 740) {
                    const offset = (width / 16) * 9 * 0.85
                    setBlockHeight(contentBlockHeight + offset)
                    setFadePercent(contentBlockHeight + 77.78 + 32 + 'px')
                } else if (width < 740 && width >= 320) {
                    const offset = (width / 16) * 9
                    setBlockHeight(contentBlockHeight + offset)
                    setFadePercent(contentBlockHeight + 51.5 + 10 + 'px')
                } else if (blockHeight !== 'auto') {
                    setBlockHeight('auto')
                }
            }
        }

        handleResize()
        const resizeDebounced = setTimeout(handleResize, 125)
        window.addEventListener('resize', handleResize)

        return () => {
            clearTimeout(resizeDebounced)
            window.removeEventListener('resize', handleResize)
        }
    }, [blockHeight, props.styling.backgroundImage])

    const containerClasses = [
        'o-grid-container--bleed',
        'wedge__container',
        `wedge__container--${props.isNarrowing ? 'narrowing' : props.isFlatTop ? 'flat-top' : 'widening'}`,
        props.className || '',
        props.isNotPulledUp ? 'wedge__container--not-pulled-up' : '',
        props.options.shouldFade
            ? props.options.seeThroughFade
                ? props.styling.fontColour === 'white'
                    ? 'wedge__container--fade-dark-transparent'
                    : 'wedge__container--fade-transparent'
                : props.styling.fontColour === 'white'
                  ? 'wedge__container--fade-dark'
                  : 'wedge__container--fade'
            : props.styling.backgroundImage
              ? 'wedge__container--no-fade'
              : '',
    ].filter(Boolean)

    const cssVariables = {
        '--font-colour': props.styling.fontColour,
        '--background-image': props.styling.backgroundImage
            ? `url(${props.styling.backgroundImage})`
            : 'none',
        '--background-colour': props.styling.backgroundColour,
        '--background-size': backgroundSize,
        '--background-align': backgroundAlign,
        '--container-padding': props.styling.containerPaddingL,
        '--fade-percent': fadePercent,
        '--custom-fade-0': customFade[0],
        '--custom-fade-1': customFade[1],
        '--is-background-fade': props.options?.shouldFade ? 'true' : 'false',
        '--start-colour':
            props.styling.fontColour === 'white'
                ? props.options.seeThroughFade
                    ? 'rgba(0,0,0,1)'
                    : 'rgba(0,0,0,0.9)'
                : props.options.seeThroughFade
                  ? 'rgba(245,245,245,1)'
                  : 'rgba(245,245,245,0.9)',
        '--end-colour':
            props.styling.fontColour === 'white'
                ? props.options.seeThroughFade
                    ? 'rgba(0,0,0,0.1)'
                    : 'rgba(0,0,0,0.2)'
                : props.options.seeThroughFade
                  ? 'rgba(245,245,245,0.1)'
                  : 'rgba(245,245,245,0.2)',
    } as React.CSSProperties

    return (
        <div
            className={containerClasses.join(' ')}
            style={{ ...cssVariables, height: blockHeight }}
            data-trackable-section={props.dataTrackableSection}
        >
            <div className='o-grid-container'>
                <div
                    className={`wedge ${isReverse ? 'wedge--reverse' : ''} o-grid-row`}
                >
                    <div
                        className='wedge__children-container'
                        ref={childrenContainer}
                        data-o-grid-colspan='12'
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wedge
