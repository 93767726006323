import React from 'react'

import ButtonLink from '../button-link/ButtonLink'
import { COMMON_STRINGS } from '../../assets/constants'
import { formattedStringWithLinks } from '../utils/helpers'

export interface StatusMessageProps {
    status: {
        success: boolean
    }
    title: string
    message: string
    showContactUs: boolean
}

const StatusMessage: React.FC<StatusMessageProps> = ({
    status,
    title = 'Demo requested',
    message = 'Thank you for contacting us. One of our team will be in touch with you soon to book in your product demo.',
    showContactUs = false,
}) => {
    const clickHereLink = `Click here to contact us.|https://www.fdiintelligence.com/form/contact-us/Customer%20support`

    if (status && !status.success) {
        title = 'Sorry, we were unable to send your enquiry.'
        message = `Please try again later or call us on ${COMMON_STRINGS.FORMS.TELEPHONE_NUMBER_UK} if this issue should persists. Many thanks for your understanding.`
    }

    const innerHtml = formattedStringWithLinks(
        COMMON_STRINGS.FORMS.HAVE_QUESTIONS,
        clickHereLink
    )

    return (
        <div className='status-message o-grid-container'>
            <div className='o-grid-row'>
                <div data-o-grid-colspan='12 M8 Mcenter L6'>
                    <h2>{title}</h2>
                </div>
            </div>
            <div className='o-grid-row'>
                <div data-o-grid-colspan='12 M8 Mcenter L4'>
                    <p>{message}</p>
                    {showContactUs && (
                        <p
                            className='status-message__contact'
                            dangerouslySetInnerHTML={innerHtml}
                        />
                    )}
                    <ButtonLink
                        title='Go to the homepage'
                        link='https://www.fdiintelligence.com/'
                    />
                </div>
            </div>
        </div>
    )
}

export default StatusMessage
