import React from 'react'

const HeaderStrip = () => {
    return (
        <div className='service'>
            <div className='o-grid-container'>
                <div className='o-grid-row'>
                    <div
                        className='service__link-container'
                        data-o-grid-colspan='12'
                    >
                        <a
                            className='service__link'
                            href='https://www.ftlocations.com/'
                        >
                            <img
                                src='https://static.fdiintelligence.com/assets/common/ft-locations-logo.svg'
                                alt='FT Locations'
                                className='service__logo'
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderStrip
