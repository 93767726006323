import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import ButtonLink from '../button-link/ButtonLink'
import SectionTitle from '../section-title/SectionTitle'

import VideoItem, { VideoItemProps } from './video-item/VideoItem'

export interface HomepageVideoBlockProps {
    playerUrl?: string
    accountId?: string
    playerId?: string
    titleLink?: string
    videos: VideoItemProps[]
    vimeoMigrationEnabled?: string
}

const VideoBlock = (props: HomepageVideoBlockProps) => {
    useEffect(() => {
        const videos = document.querySelectorAll('.video-js')
        let activePlayerId: string | null = null

        const handleVideoPlayback = (e: Event) => {
            if (!activePlayerId) {
                activePlayerId =
                    (e.target as HTMLElement).dataset.videoId || null
            } else if (
                activePlayerId !== (e.target as HTMLElement).dataset.videoId
            ) {
                const videoElement = document.querySelector(
                    `[data-video-id="${activePlayerId}"] > video`
                ) as HTMLVideoElement | null
                if (videoElement) {
                    videoElement.pause()
                }
                activePlayerId =
                    (e.target as HTMLElement).dataset.videoId || null
            }
        }

        videos.forEach(video => {
            video.addEventListener('play', handleVideoPlayback)
        })

        return () => {
            videos.forEach(video => {
                video.removeEventListener('play', handleVideoPlayback)
            })
        }
    }, [])

    const dataTrackable = 'Video'

    return (
        <div
            className='videos'
            data-trackable={dataTrackable}
            data-trackable-section='latest-videos'
        >
            <div className='o-grid-row videos__row ml0'>
                <SectionTitle
                    titleContent={<a href={props.titleLink}>Latest videos</a>}
                />
                {props.videos.map((video, i) => (
                    <VideoItem
                        key={video.id}
                        id={video.id}
                        title={video.name}
                        videoPlayerUrl={props.playerUrl}
                        accountId={props.accountId || ''}
                        playerId={props.playerId || ''}
                        dataTrackable={`Slot ${i + 1}`}
                        dataTrackableContextText={`On Location Video ${i + 1}`}
                    />
                ))}
                <div
                    className='o-grid-column mobile-only'
                    data-o-grid-colspan='12'
                >
                    <div className='videos__button-container'>
                        <ButtonLink
                            link={props.titleLink || ''}
                            title='See all On Location Videos'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

VideoBlock.propTypes = {
    playerUrl: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    titleLink: PropTypes.string.isRequired,
    vimeoMigrationEnabled: PropTypes.string,
}

export default VideoBlock
