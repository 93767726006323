import React from 'react'

import UserIcon from './UserIcon'
import { useFeatureFlags } from '../../provider/FeatureFlagsProvider'

const LoginMenuMobile = () => {
    const { isFeatureDisabled } = useFeatureFlags()

    if (isFeatureDisabled('isRegistrationsEnabled')) {
        return null
    }

    return (
        <div className='login-menu'>
            <UserIcon />
        </div>
    )
}

export default LoginMenuMobile
