import React from 'react'

export interface LoggedInContextProps {
    setIsLoggedIn?: (isLoggedIn: boolean) => void
    isLoggedIn: boolean
}

const LoggedInContext = React.createContext<LoggedInContextProps>({
    isLoggedIn: false,
    setIsLoggedIn: () => {},
})

export default LoggedInContext
