import React from 'react'
import { Contacts, Rates, Specifications } from '../../components/commercial'
import { WedgeProps } from '../../components/wedge/Wedge.props'

export interface CommercialContent {
    options: WedgeProps['options']
    styling: WedgeProps['styling']
    sections: {
        intro: string[]
        stats: { value: string; label: string }[]
        tabs: { label: string; component: React.ReactNode }[]
    }
}

export const commercialContent: CommercialContent = {
    options: {
        demo: true,
        media: true,
        ctaButton: true,
        mediaType: 'video',
        renderOrder: 'media',
    },
    styling: {
        containerPaddingL: '0 0 120px',
        mediaCustomBorderRadius: '15px',
        backgroundColour: '#ffffff',
        fontColour: '#222222',
    },
    sections: {
        intro: [
            'fDi tracks the companies and sectors making moves, and stays up to date on investment destinations, hot topics, data trends, interviews with leaders and more. Many of our articles are created using data from our tools.',
            'Published bi-monthly fDi in print has a circulation of 12,250 active corporate readers and cross border investment professionals.',
            'fDiIntelligence.com contains all of the content published in our print edition, a searchable archive of articles and reports, and more.',
        ],
        stats: [
            {
                value: '206,925',
                label: 'page views',
            },
            {
                value: '117,043',
                label: 'visitors',
            },
            {
                value: '48s',
                label: 'average active time on page',
            },
        ],
        tabs: [
            {
                label: 'Specifications',
                component: <Specifications />,
            },
            {
                label: 'Rates',
                component: <Rates />,
            },
            {
                label: 'Contacts',
                component: <Contacts />,
            },
        ],
    },
}
