import React, { useContext } from 'react'

import LoggedInContext from '../../context/LoggedInContext'
import { PROFILE_URL, REGISTER_URL } from '../../../assets/constants'

const UserIcon = () => {
    const { isLoggedIn } = useContext(LoggedInContext)

    return (
        <>
            <a
                className='user-icon-link'
                href={isLoggedIn ? PROFILE_URL : REGISTER_URL}
            >
                <i className='o-icons-icon o-icons-icon--user' />
            </a>
        </>
    )
}

export default UserIcon
