import React from 'react'

const FalsePlayButton = () => {
    return (
        <>
            <div className='false-play-button' />
        </>
    )
}

export default FalsePlayButton
