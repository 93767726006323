import React from 'react'

interface HydrationProps {
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
    children: React.ReactNode
}

const Hydration: React.FC<HydrationProps> = ({
    id,
    children = '',
    ...rest
}) => {
    return (
        <div id={id} {...rest}>
            {children}
        </div>
    )
}

export default Hydration

export const withHydration = <P extends object>(
    WrappedComponent: React.ComponentType<P>,
    id: string
) => {
    const WithHydration: React.FC<P> = props => {
        return (
            <Hydration id={id}>
                <WrappedComponent {...props} />
            </Hydration>
        )
    }

    WithHydration.displayName = `WithHydration(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

    return WithHydration
}
