import React, { useState, useEffect } from 'react'

import BurgerMenuControls from './burger-menu-controls/BurgerMenuControls'
import HeaderAdvert from './header-advert/HeaderAdvert'
import HeaderStrip from './header-strip/HeaderStrip'
import LeadForensicsScript from './lead-forensics/LeadForensicsScript'
import LoginMenu from './login-menu/LoginMenu'
import LoginMenuMobile from './login-menu/LoginMenuMobile'
import Logo from './logo/Logo'
import MobileSearchTrigger from './mobile-search-trigger/MobileSearchTrigger'
import MobileSearch from './mobile-search/MobileSearch'
import { navItems } from './nav-items'
import PrimaryNav from './primary-nav/PrimaryNav'
import SearchBox from './search-box/SearchBox'
import Hydration from '../Hydration'
import SecondaryNav from './secondary-nav/SecondaryNav'

const navItemsJsonString = JSON.stringify(navItems)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Header = (props: any) => {
    const [showSearch, setShowSearch] = useState(false)
    const [width, setWidth] = useState(0)
    const closeSearch = () => {
        setShowSearch(false)
    }

    const openSearch = () => {
        setShowSearch(true)
    }

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const oHeaderServices = require('@financial-times/o-header-services/browser')
        oHeaderServices.init()
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowSizeChange)
        setWidth(window.innerWidth)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    /*
     * recursivly set the menus needed to be highlight
     */
    const strippedCurrentUrl = props.currentUrl.split('?')[0]

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isCurrentItem = (item: any) => {
        const isCurrent = (item.isCurrent =
            (item.items && item.items.some(isCurrentItem)) ||
            (item.subItems && item.subItems.some(isCurrentItem)) ||
            item.route === strippedCurrentUrl)
        return isCurrent
    }

    const highlightedNavItems =
        typeof navItemsJsonString === 'string'
            ? JSON.parse(navItemsJsonString)
            : navItemsJsonString
    isCurrentItem({ items: highlightedNavItems })

    /*
     *  Empty div is a placeholder for the 'Saved searches' feature in the design
     */
    const MobileSearchTriggerWithProps = (
        <MobileSearchTrigger
            isSearchOpen={showSearch}
            openSearch={openSearch}
            closeSearch={closeSearch}
        />
    )

    return (
        <Hydration id='header'>
            <header
                className='o-header-services'
                data-o-component='o-header-services'
            >
                <HeaderAdvert />
                <LeadForensicsScript />
                <HeaderStrip />
                <div className='o-grid-container'>
                    <div className='o-grid-row'>
                        <BurgerMenuControls />
                        <Logo width={width} />
                        <SearchBox />
                        <div
                            className='saved-searches'
                            data-o-grid-colspan='3 L2 Lpush3'
                        />
                        {width < 740 ? (
                            <div
                                className='search-wrapper'
                                data-o-grid-colspan='3 Mpush8'
                            >
                                {MobileSearchTriggerWithProps}
                                <LoginMenuMobile />
                            </div>
                        ) : (
                            <>
                                {MobileSearchTriggerWithProps}
                                <LoginMenu />
                            </>
                        )}
                    </div>
                </div>
                {showSearch && <MobileSearch closeSearch={closeSearch} />}
                <PrimaryNav items={highlightedNavItems} />
                <SecondaryNav items={highlightedNavItems} />
            </header>
        </Hydration>
    )
}

Header.defaultProps = {
    currentUrl: '',
}

export default Header
