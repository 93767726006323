import React from 'react'

export interface TrackingContextType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    events: any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setEvents: (events: any[]) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TrackingContext = React.createContext<TrackingContextType>({
    events: [],
    setEvents: () => {},
})

export default TrackingContext
