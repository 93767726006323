import { PageEntityLoadedData } from '../types'

export const articleEntityTracking = (articleData: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    article: any
    genre: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    articleType?: any
}) => {
    const { article } = articleData
    const articleTags = article.tags

    const getAuthorName = (authorsList: { name: string }[]) => {
        if (authorsList && authorsList.length) {
            const authorNames = authorsList.map((author: { name: string }) => {
                return author.name
            })
            return authorNames.length === 1
                ? authorNames[0]
                : authorNames.join(', ')
        }
        return null
    }

    const contentTags =
        articleTags &&
        Object.keys(articleTags)
            .reduce(
                (
                    acc: { tag_name: string; tag_category: string }[],
                    key: string
                ) => {
                    const tagObject: {
                        tag_name: string
                        tag_category: string
                    } = {
                        tag_name: articleTags[key],
                        tag_category: key,
                    }

                    if (Array.isArray(articleTags[key])) {
                        const transformedTagsArray = articleTags[key].map(
                            (tag: { label: string }) => {
                                return {
                                    tag_name: tag.label,
                                    tag_category: key,
                                }
                            }
                        )

                        acc.push(...transformedTagsArray)
                        return acc
                    }

                    if (key === 'sections') {
                        const sectionsTag = Object.keys(
                            articleTags.sections
                        ).map((sectionTag: string) => {
                            return {
                                tag_name: sectionTag,
                                tag_category: 'sections',
                            }
                        })

                        acc.push(...sectionsTag)
                        return acc
                    }

                    tagObject.tag_name =
                        articleTags[key] && articleTags[key].label

                    acc.push(tagObject)
                    return acc
                },
                []
            )
            .filter(
                (tag: { tag_name: string }) =>
                    !!tag.tag_name && tag.tag_name !== '__typename'
            )

    // Replace table tags by space
    const articleBody =
        (article.body_text &&
            article.body_text
                .replace(/<(table|tr|td|th|br|li)[^>]*>/g, ' ')
                .replace(/<\/p>/g, ' ')) ||
        ''

    // Remove html tags
    const cleanArticleContent = articleBody.replace(/<\/?[^>]+(>|$)/g, '')

    // Replace some special characters
    const articleContent = cleanArticleContent
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ')
        .replace(/&ndash;/g, '-')
        .replace(/&ldquo;|&rdquo;/g, '"')
    // eslint-disable-next-line no-mixed-operators
    const articleWords = (articleContent && articleContent.split(/\s+/)) || []
    const wordCount = articleWords.filter(
        (word: string) => word !== '' && word !== '-'
    ).length
    const isSponsor =
        Array.isArray(article.advertorial_sponsor) &&
        article.advertorial_sponsor.length === 0
            ? null
            : article.advertorial_sponsor
    const sponsorName =
        (article.advertorial_sponsor &&
            article.advertorial_sponsor.map((sponsor: { name: string }) => {
                return sponsor.name
            })) ||
        []

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'Article Entity Loaded',
        article: {
            data: {
                content_id: article.id,
                article_title: article.title,
                content_type: 'article',
                content_category: articleData.genre,
                article_type: articleData.articleType || null,
                author_name: getAuthorName(article.author),
                published_datetime: article.publication_date,
                free_access: article.paywall || true,
                sponsored_post: !!isSponsor,
                sponsor_name: sponsorName.join(', ') || null,
                article_word_count: wordCount,
                content_tags: contentTags || [],
            },
        },
    })
}

export const formTracking = (
    hasError: boolean,
    label: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formInputData: any
) => {
    const pushData = {
        event: 'analytics_event',
        event_payload: {
            schema: 'iglu:com.financialtimes/form_submission/jsonschema/1-0-1',
            data: {
                category: 'Form Submission',
                action: hasError ? 'Submission Failed' : 'Submission Success',
                label: label,
                form_input: JSON.stringify(formInputData),
            },
        },
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(pushData)
}

/**
 * Creates a 'Page Entity Loaded' record
 * @param {string} assetType - The type of page that has been loaded ('homepage', 'content', etc.)
 */
import React from 'react'

const PageEntityTrackingScript = ({ data }: { data: PageEntityLoadedData }) => {
    return (
        <script
            dangerouslySetInnerHTML={{
                __html: `
                    window.ft_pageview_id = window.ft_pageview_id || Math.round(Math.random() * 10000000000000000);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'Page Entity Loaded',
                        page: {
                            data: {
                                asset_type: '${data.asset_type}',
                                system_product: 'fDi-Intelligence',
                                system_product_cluster: 'Institutional',
                                ft_pageview_id: window.ft_pageview_id,
                                barrier_name: null,
                            },
                        },
                    });
                `,
            }}
        />
    )
}

export default PageEntityTrackingScript

export const GtmSnippet = () => {
    return (
        <>
            <noscript aria-hidden='true'>
                <iframe
                    src='//www.googletagmanager.com/ns.html?id=GTM-KXBF5D'
                    height='0'
                    width='0'
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            </noscript>
        </>
    )
}
