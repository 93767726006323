import React from 'react'

import Image from '../image/Image'

import { ImageLinkProps } from '../../types'
const ImageLink = ({
    href,
    target = '_self',
    imgSrc,
    dataTrackable,
    hasShadow = false,
}: ImageLinkProps) => {
    return (
        <div
            className={
                'image-link__container' +
                (hasShadow === true ? ' image-link__shadowed' : '')
            }
        >
            <a
                className='image-link'
                href={href}
                target={target}
                data-trackable={dataTrackable}
            >
                <Image fallbackSrc='' src={imgSrc} />
            </a>
        </div>
    )
}

export default ImageLink
