import React from 'react'

export interface VideoItemProps {
    id: string
    name?: string
    accountId: string
    playerId: string
    title?: string
    videoPlayerUrl?: string
    dataTrackable: string
    dataTrackableContextText: string
    video?: {
        title: string
    }
}

const VideoItem: React.FC<VideoItemProps> = props => {
    const vimeoPlayerUrl = props.videoPlayerUrl

    return (
        <>
            <div className='video__card' data-o-grid-colspan='12 L4'>
                <div className='video__border' data-o-grid-colspan='12 L0' />
                <div className='video__player' data-o-grid-colspan='4 M3 L12'>
                    <div className='video__wrapper--outer'>
                        <div
                            className='video__wrapper--inner'
                            data-trackable={props.dataTrackable}
                            data-trackable-context-text={
                                props.dataTrackableContextText
                            }
                        >
                            <iframe
                                src={`${vimeoPlayerUrl}${props.id}`}
                                frameBorder='0'
                                allow='autoplay; fullscreen; picture-in-picture'
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                title={props.title || ''}
                            />
                        </div>
                    </div>
                </div>
                <div
                    id={`video-title--${props.id}`}
                    className='video__title'
                    data-o-grid-colspan='8 M9 L12'
                >
                    <h2 className='video__title-heading'>
                        {props.video && props.video.title
                            ? props.video.title
                            : props.title}
                    </h2>
                </div>
            </div>
        </>
    )
}

export default VideoItem
