import React, { useEffect } from 'react'

const GtmSnippet = () => {
    useEffect(() => {
        ;(function () {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const Gtm = function (w: any, d: any, s: any, l: any, i: any) {
                w[l] = w[l] || []
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
                })
                const f = d.getElementsByTagName(s)[0]
                const j = d.createElement(s)
                j.async = true
                const dl = l !== 'dataLayer' ? '&l=' + l : ''
                j.src =
                    'https://sst.fdiintelligence.com/direktor-etikirane.js?id=' +
                    i +
                    dl
                f.parentNode.insertBefore(j, f)
            }
            try {
                Gtm(window, document, 'script', 'dataLayer', 'GTM-KXBF5D')
            } catch (error) {
                console.error('Error initializing GTM:', error)
            }
        })()
    }, [])

    return (
        <noscript aria-hidden='true'>
            <iframe
                src='//www.googletagmanager.com/ns.html?id=GTM-KXBF5D'
                height='0'
                width='0'
                style={{ display: 'none', visibility: 'hidden' }}
            />
        </noscript>
    )
}

export default GtmSnippet
