import React from 'react'

import FalsePlayButton from '../false-play-button/falsePlayButton'
import { ImageProps } from '../../types'

const Image = ({
    app = 'fdi-frontend',
    src,
    fallbackSrc = '',
    style = {},
    alt = '',
    parameters = {},
}: ImageProps) => {
    const environment = process.env.NODE_ENV || 'production'

    const srcHasHostName = (src: string) => {
        return /^https?:\/\//i.test(src)
    }

    const isSvg = () => {
        return /\.svg$/i.test(src)
    }

    const willUseImageService = () => {
        return (
            srcHasHostName(src) &&
            !['test', 'development'].includes(environment) &&
            !isSvg()
        ) // Disable image service for any svg files
    }

    const buildSource = (src: string) => {
        return willUseImageService()
            ? `https://www.ft.com/__origami/service/image/v2/images/raw/${buildURL(src)}`
            : src
    }

    const buildURL = (src: string) => {
        const host = srcHasHostName(src) ? '' : baseURL()

        return encodeURI(host + src + buildQueryString())
    }

    const baseURL = () => {
        const hosts = {
            staging: 'https://test.fdiintelligence.com',
            production: 'https://www.fdiintelligence.com',
        }

        return hosts[environment as keyof typeof hosts]
    }

    const buildQueryString = () => {
        parameters = parameters || {}

        parameters['source'] = app || 'fdi-frontend'

        return Object.keys(parameters).reduce((str, key, i) => {
            const delimiter = i === 0 ? '?' : '&'
            const encodedKey = encodeURIComponent(key)
            const encodedVal = encodeURIComponent(parameters[key] as string)
            return `${str}${delimiter}${encodedKey}=${encodedVal}`
        }, '')
    }

    const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.target as HTMLImageElement
        target.onerror = null

        if (fallbackSrc) {
            target.src = buildSource(fallbackSrc)
        }
    }

    src = buildSource(src)
    const showPlayButton = parameters && parameters.isVideo
    return (
        <>
            {showPlayButton && <FalsePlayButton />}
            <img
                className='image-component-image'
                style={style}
                alt={alt}
                src={src}
                onError={onError}
            />
        </>
    )
}

export default Image
