/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import PropTypes from 'prop-types'

const SecondaryNav = ({ items }: any) => {
    const primaryNavItem = items.find((item: any) => item.isCurrent)
    let secondaryNavItems = (primaryNavItem && primaryNavItem.items) || []
    const subMenu = secondaryNavItems.find(
        (item: any) => item.isCurrent && item.subItems
    )
    if (subMenu) {
        secondaryNavItems = subMenu.subItems
    }
    if (
        secondaryNavItems.length === 0 ||
        (primaryNavItem.breadcrumbMode &&
            secondaryNavItems.find(
                (item: any, index: any) => item.isCurrent && index === 0
            ))
    ) {
        return null
    }

    if (primaryNavItem.breadcrumbMode) {
        secondaryNavItems = secondaryNavItems.filter(
            (item: any, index: any) => item.isCurrent || index === 0
        )
    }

    const viewFrom = secondaryNavItems.find(
        (item: any) => item.route === '/opinion/view-from' && item.isCurrent
    )
    if (viewFrom) {
        secondaryNavItems = viewFrom.subItems
    }

    return (
        <nav
            className='o-header-services__secondary-nav'
            aria-label='secondary'
            data-o-header-services-nav=''
        >
            <div
                className='o-header-services__secondary-nav-content'
                data-o-header-services-nav-list=''
            >
                <a
                    href={primaryNavItem.route}
                    className='o-header-services__secondary-nav-parent'
                >
                    {primaryNavItem.name}
                </a>
                {subMenu ? (
                    <>
                        <i className='icon o-icons-icon--arrow-right nav-icon' />
                        <a
                            href={subMenu.route}
                            className='o-header-services__secondary-nav-parent'
                        >
                            {subMenu.name}
                        </a>
                    </>
                ) : (
                    ''
                )}
                {viewFrom ? (
                    <>
                        <i className='icon o-icons-icon--arrow-right nav-icon' />
                        <div className='o-header-services__secondary-nav-parent'>
                            {viewFrom.name}
                        </div>
                    </>
                ) : (
                    ''
                )}
                <div className='o-header-services__secondary-nav-divider' />
                <ol
                    className='o-header-services__secondary-nav-list o-header-services__secondary-nav-list--ancestors'
                    aria-label='Ancestor sections'
                >
                    {secondaryNavItems.map((item: any) => {
                        const attributes = {
                            href: item.route,
                            ...(item.isCurrent
                                ? { 'aria-current': 'true' }
                                : {}),
                        }

                        return (
                            <li key={item.name} className='ancestor-item'>
                                <a
                                    className='ancestor-item__anchor'
                                    {...attributes}
                                    aria-current={
                                        attributes['aria-current'] as
                                            | 'page'
                                            | 'true'
                                            | undefined
                                    }
                                >
                                    {item.name}
                                </a>
                            </li>
                        )
                    })}
                </ol>
            </div>
            <button
                className='o-header-services__scroll-button o-header-services__scroll-button--left'
                title='scroll left'
                aria-hidden='true'
                disabled
            />
            <button
                className='o-header-services__scroll-button o-header-services__scroll-button--right'
                title='scroll right'
                aria-hidden='true'
                disabled
            />
        </nav>
    )
}

const routeCommonShape = {
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
}

SecondaryNav.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            ...routeCommonShape,
            items: PropTypes.arrayOf(PropTypes.shape(routeCommonShape)),
        })
    ).isRequired,
}

export default SecondaryNav
