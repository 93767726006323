interface ScrollDepth {
    totalAttentionTime: number
    startAttentionTime: number | null
    endAttentionTime: number | null
    attentionTimeout: NodeJS.Timeout | null
    attentionInterval: number
    percentages: number[]
    init: () => void
    intersectionCallback: (
        observer: IntersectionObserver,
        changes: IntersectionObserverEntry[]
    ) => void
    fire: (percentage: string) => void
    createMarker: (observer: IntersectionObserver, percentage: number) => void
    getAttention: () => number
    endAttention: () => void
    startAttention: () => void
}

const scrollDepth: ScrollDepth = {
    totalAttentionTime: 0,
    startAttentionTime: null,
    endAttentionTime: null,
    attentionTimeout: null,
    attentionInterval: 15000,
    percentages: [25, 50, 75, 100],

    init: function () {
        if (window.IntersectionObserver) {
            const observer = new IntersectionObserver(changes => {
                this.intersectionCallback(observer, changes)
            })
            this.percentages.forEach(percentage => {
                this.createMarker(observer, percentage)
            })
        }
    },

    intersectionCallback: function (
        observer: IntersectionObserver,
        changes: IntersectionObserverEntry[]
    ) {
        changes.forEach(change => {
            if (change.isIntersecting || change.intersectionRatio > 0) {
                const scrollDepthMarkerElement = change.target as HTMLElement
                this.fire(
                    scrollDepthMarkerElement.getAttribute('data-percentage') ||
                        ''
                )
                if (scrollDepthMarkerElement.parentNode) {
                    scrollDepthMarkerElement.parentNode.removeChild(
                        scrollDepthMarkerElement
                    )
                }
                observer.unobserve(scrollDepthMarkerElement)
            }
        })
    },

    fire: function (percentage: string) {
        const getAttention = this.getAttention()
        const data = {
            action: 'scrolldepth',
            category: 'page',
            meta: {
                percentagesViewed: percentage,
                attention: getAttention,
            },
        }
        document.body.dispatchEvent(
            new CustomEvent('oTracking.event', {
                bubbles: true,
                cancelable: true,
                detail: data,
            })
        )
    },

    createMarker: function (
        observer: IntersectionObserver,
        percentage: number
    ) {
        const targetElement = document.createElement('div')
        targetElement.className = 'scroll-depth__marker'
        targetElement.style.position = 'absolute'

        if (percentage === 100) {
            targetElement.style.top = `calc(${percentage}% - 1px)`
        } else {
            targetElement.style.top = `${percentage}%`
        }
        targetElement.style.bottom = '0'
        targetElement.style.width = '100%'
        targetElement.style.zIndex = '-1'
        targetElement.setAttribute('data-percentage', percentage.toString())
        document.body.appendChild(targetElement)
        observer.observe(targetElement)
    },

    getAttention: function () {
        this.endAttention()
        this.startAttention()
        return this.totalAttentionTime
    },

    endAttention: function () {
        if (this.startAttentionTime) {
            this.endAttentionTime = new Date().getTime()
            this.totalAttentionTime += Math.round(
                (this.endAttentionTime - this.startAttentionTime) / 1000
            )
            if (this.attentionTimeout) {
                clearTimeout(this.attentionTimeout)
            }
            this.startAttentionTime = null
        }
    },

    startAttention: function () {
        if (this.attentionTimeout) {
            clearTimeout(this.attentionTimeout)
        }

        if (this.startAttentionTime === null) {
            this.startAttentionTime = new Date().getTime()
        }

        this.attentionTimeout = setTimeout(() => {
            this.startAttention()
        }, this.attentionInterval)
    },
}

export default scrollDepth
