import React from 'react'
import { FieldProps, FormikProps } from 'formik'
import OFormsWrapper, {
    OFormsWrapperProps,
} from '../o-forms-wrapper/OFormsWrapper'

interface SelectItem {
    name: string
    value?: string
    heading?: boolean
    items?: SelectItem[]
}

export interface SelectProps {
    label: string
    options: (SelectItem | string)[]
    required?: boolean
    errorText?: boolean | string
    optional?: boolean
    promptText?: string
    disabled?: boolean
    wide?: boolean
    small?: boolean
    field: FieldProps['field']
    form?: FormikProps<any> // eslint-disable-line @typescript-eslint/no-explicit-any
    error?: boolean
}

const Select: React.FC<SelectProps> = ({
    label,
    errorText = '',
    required = false,
    optional = false,
    promptText = '',
    wide = false,
    options,
    small = false,
    field,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    form,
    error = false,
    ...otherProps
}) => {
    const name = field.name

    const wrapperProps = {
        name,
        label,
        errorText,
        optional,
        promptText,
        wide,
        required,
        error,
    }

    const inputClasses = [
        'o-forms__select',
        small ? 'o-forms__text--small' : '',
    ]
        .filter(Boolean)
        .join(' ')

    return (
        <OFormsWrapper {...(wrapperProps as OFormsWrapperProps)}>
            <select
                id={name}
                className={inputClasses}
                name={field.name}
                onChange={field.onChange}
                required={required}
                defaultValue={field.value}
                {...otherProps}
            >
                <option value=''>Select</option>
                {options.map(option => {
                    const opt =
                        typeof option === 'string' ? { name: option } : option
                    return (
                        <React.Fragment key={opt.name}>
                            {opt.heading ? (
                                <optgroup label={opt.name}>
                                    {opt.items?.map(item => (
                                        <option
                                            key={item.name}
                                            value={item.value}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </optgroup>
                            ) : (
                                <option value={opt.value}>{opt.name}</option>
                            )}
                        </React.Fragment>
                    )
                })}
            </select>
        </OFormsWrapper>
    )
}

export default Select
