import React from 'react'

import ImageLink from '../../image-link/ImageLink'
import { LogoProps } from '../../../types'

const Logo = ({ width }: LogoProps) => {
    return (
        <div
            className='logo'
            data-o-grid-colspan={
                width < 740 ? '6 M4 Mpull2 L3 Lpull9' : '6 M4 Mpull4 L3 Lpull9'
            }
        >
            <ImageLink
                imgSrc='https://static.fdiintelligence.com/assets/common/logo.svg'
                href='/'
            />
        </div>
    )
}

export default Logo
