import { getState } from '../utils/helpers'
import oHeaderServices from '@financial-times/o-header-services/browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import GtmSnippet from '../components/gtm-snippet/GtmSnippet'
import VideoBlock from '../components/homepage-video-block/HomepageVideoBlock'
import Header from '../components/header/Header'
import CommercialLayout from '../components/commercial/layout'
import NewsletterSignup from '../components/newsletter-signup/NewsletterSignupComponent'

const state = getState()

// /**
//  * Renders a specific part of the UI by element id with a React component.
//  * @param {string} elementId - The id of the element to render.
//  * @param {React.ComponentType} Component - The React component to render.
//  * @param {object} props - The props to pass to the component.
//  */
const renderComponent = (
    elementId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: React.ComponentType<any>,
    props: object = {}
): void => {
    const container = document.getElementById(elementId)
    if (container) {
        const root = createRoot(container)
        root.render(<Component {...props} />)
    }
}

window.addEventListener('DOMContentLoaded', () => {
    oHeaderServices.init()
    renderComponent('gtm-snippet', GtmSnippet)
    renderComponent('video-block', VideoBlock, {
        videos: state.videos,
        playerUrl: state.environment.vimeoPlayerUrl,
        vimeoMigrationEnabled: state.environment.vimeoMigrationEnabled,
    })
    renderComponent('header', Header)
    renderComponent('commercial', CommercialLayout)
    renderComponent('newsletter-signup', NewsletterSignup, {
        url: state.url,
        referrer: state.referrer,
        reCaptchaKey: state.environment.reCaptchaKey,
        name: state.name,
        apolloServerUrl: state.environment.apolloServerUrl,
    })
})
