const hasConsent = (string = '') => {
    string = decodeURIComponent(string)

    return (
        string.includes('demographicadsOnsite:on') &&
        string.includes('behaviouraladsOnsite:on')
    )
}

export default hasConsent
