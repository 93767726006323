import React, { createContext, useContext, useState } from 'react'
import { FeatureFlagsProviderProps, FeatureOptions } from '../../types'

const FeatureFlagsContext = createContext({
    featureFlags: {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isFeatureEnabled: (flagName: string): boolean => false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isFeatureDisabled: (flagName: string): boolean => false,
})

/**
 * Provider for feature flags, which are used to check if a feature is enabled or disabled
 * @param {object} initialFlags
 * @returns {Provider} FeatureFlagsProvider
 */
export const FeatureFlagsProvider = ({
    children,
    initialFlags,
}: FeatureFlagsProviderProps): React.ReactNode => {
    const [featureFlags] = useState<FeatureOptions>(initialFlags || {})

    const isFeatureEnabled = (flagName: string): boolean => {
        return featureFlags[flagName] === true
    }

    const isFeatureDisabled = (flagName: string): boolean => {
        return featureFlags[flagName] !== true
    }

    return (
        <FeatureFlagsContext.Provider
            value={{ featureFlags, isFeatureEnabled, isFeatureDisabled }}
        >
            {children}
        </FeatureFlagsContext.Provider>
    )
}

export const useFeatureFlags = () => {
    return useContext(FeatureFlagsContext)
}
