export const orgTypeOptions = [
    {
        name: 'Public bodies',
        heading: true,
        items: [
            {
                name: 'Economic Development Agency',
            },
            {
                name: 'Government Agency',
            },
            {
                name: 'International Organisation',
            },
        ],
    },
    {
        name: 'Private Bodies',
        heading: true,
        items: [
            {
                name: 'Multinational Corporation',
            },
            {
                name: 'Consultant',
            },
        ],
    },
    {
        name: 'Academic',
        heading: true,
        items: [
            {
                name: 'University/College/Student',
            },
            {
                name: 'Research Organisation',
            },
        ],
    },
    {
        name: 'Other',
        heading: true,
        items: [
            {
                name: 'Banking and Financial Services',
            },
            {
                name: 'Construction',
            },
            {
                name: 'Business services',
            },
            {
                name: 'Law/Legal Services',
            },
            {
                name: 'Manufacturing',
            },
            {
                name: 'Media/Marketing',
            },
            {
                name: 'Life sciences',
            },
            {
                name: 'Real Estate',
            },
            {
                name: 'Retail',
            },
            {
                name: 'Software & IT',
            },
            {
                name: 'FinTech',
            },
            {
                name: 'Travel & Tourism',
            },
            {
                name: 'Transport/Logistics',
            },
            {
                name: 'Energy/Utilities',
            },
            {
                name: 'Other',
            },
        ],
    },
]
