import { Request, Response } from 'express'

/**
 * Get the origin URL from the request
 * @param req - The request object
 * @returns The origin URL
 */
export const getOriginUrl = (req: Request) => {
    const host =
        (req ? req.headers['original-host'] : window.location.host) ||
        'localhost:8000'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const protocol = /^localhost(:\d+)?$/.test(host as string)
        ? 'http:'
        : 'https:'
    // return `${protocol}//${host}`
    return 'https://www.fdiintelligence.com'
}

export const getState = () => {
    const state = document.getElementById('state')
    return state ? JSON.parse(state.innerHTML) : {}
}

/**
 * Clean HTML tags from a string
 * @param text - The string to clean
 * @returns The cleaned string
 */
export const cleanHtmlTags = (text: string) =>
    text.replace(/<\/?[^>]+(>|$)/g, '')

export const getDefaultProps = (req: Request, res: Response) => {
    return {
        environment: {
            vimeoMigrationEnabled: process.env.VIMEO_MIGRATION_ENABLED || false,
            vimeoPlayerUrl: process.env.VIMEO_PLAYER_URL || '',
            isNewCMPEnabled: process.env.IS_NEW_CMP_ENABLED || false,
            reCaptchaKey: process.env.RECAPTCHA_KEY || '',
            apolloServerUrl: process.env.APOLLO_SERVER_URL || '',
        },
        url: req.query.url,
        referrer: req.query.referrer,
        origin: getOriginUrl(req),
        js: res.locals.assetsFileNames.js,
        css: res.locals.assetsFileNames.css,
    }
}
