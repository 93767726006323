import React from 'react'

const specificationsData = [
    {
        format: 'Full Page',
        details: 'Run of magazine',
        size: '297 x 210',
        bleed_size: '303 x 216',
        type_area: '266 x 178',
    },
    {
        format: 'Double Page Spread',
        details: 'Run of magazine',
        size: '297 x 420',
        bleed_size: '303 x 426',
        type_area: '266 x 380',
    },
    {
        format: 'Half Page',
        details: 'Run of magazine',
        size: '-',
        bleed_size: '-',
        type_area: '131 x 178',
    },
    {
        format: 'Outside Back Cover',
        details: 'Run of magazine',
        size: '297 x 210',
        bleed_size: '303 x 216',
        type_area: '266 x 178',
    },
    {
        format: 'Inside Front Cover (Single)',
        details: 'Run of magazine',
        size: '297 x 210',
        bleed_size: '303 x 216',
        type_area: '266 x 178',
    },
    {
        format: 'Inside Front Cover (Double)',
        details: 'Run of magazine',
        size: '297 x 420',
        bleed_size: '303 x 426',
        type_area: '266 x 380',
    },
    {
        format: 'Half Page Horizontal',
        details: 'Run of magazine',
        size: '-',
        bleed_size: '-',
        type_area: '131 x 178',
    },
    {
        format: 'Half Page Vertical',
        details: 'Run of magazine',
        size: '-',
        bleed_size: '-',
        type_area: '266 x 86',
    },
]

const Specifications = () => (
    <table>
        <thead>
            <tr>
                <th>Format</th>
                <th>Details</th>
                <th>Size (Height x Width)</th>
                <th />
                <th>Format</th>
            </tr>
        </thead>
        <tbody>
            {specificationsData.map((specification, index) => (
                <tr key={index}>
                    <td>{specification.format}</td>
                    <td>{specification.details}</td>
                    <td>{specification.size}</td>
                    <td>{specification.bleed_size}</td>
                    <td>{specification.type_area}</td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default Specifications
