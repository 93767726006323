import React from 'react'

export interface ButtonLinkProps {
    title: string
    link: string
    target?: string
    disabled?: boolean
    onClick?: () => void
    className?: string
    buttonTrackable?: string
    children?: React.ReactNode
    dontTrack?: boolean
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
    title = '',
    link = '',
    target = '_self',
    disabled = false,
    onClick = () => {},
    className = '',
    buttonTrackable = '',
    dontTrack = false,
    children = null,
}) => {
    const linkClassName = [
        className,
        'button-link',
        ...(disabled ? ['button-link--disabled'] : []),
    ].join(' ')
    const hasChildren = !!children

    return (
        <div className='button-link-container'>
            <a
                className={linkClassName}
                href={link}
                target={target}
                onClick={onClick}
                data-trackable={buttonTrackable}
                data-o-tracking-do-not-track={dontTrack} // eslint-disable-line no-unneeded-ternary
            >
                {hasChildren && children}
                {!hasChildren && (
                    <span className='button-link__title'>{title}</span>
                )}
            </a>
        </div>
    )
}

export default ButtonLink
