import React, { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ratesData: Record<string, any[]> = {
    'fDi - Colour': [
        {
            format: 'Full Page',
            price: '8044',
        },
        {
            format: 'Half Page',
            price: '4246',
        },
        {
            format: 'Double Page Spread',
            price: '16089',
        },
        {
            format: 'Outside Back Cover',
            price: '9260',
        },
        {
            format: 'Inside Front Cover (Single)',
            price: '8819',
        },
        {
            format: 'Inside Front Cover (Double)',
            price: '17086',
        },
    ],
    'fDiIntellgence.com': [
        {
            format: 'fDi Homepage Takeover',
            price: '8000',
        },
        {
            format: 'fDi Site Domination',
            price: '12500',
        },
        {
            format: 'fDi Homepage Takeover + fDi Site Domination',
            price: '17500',
        },
    ],
    'fDi Intelligence e-Newsletter': [
        {
            format: '1-Month (2x e-Newsletters)',
            price: '7000',
        },
    ],
    'fDi - Partner Content packages': [
        {
            format: '1x Online Article',
            price: '15000',
        },
        {
            format: '2x Online Articles',
            price: '20000',
        },
        {
            format: 'Video - In conversation with',
            price_prefix: 'starting from ',
            price: '40000',
        },
        {
            format: 'Video - fDi on Location',
            price_prefix: 'starting from ',
            price: '40000',
        },
        {
            format: 'Animated Video',
            price_prefix: 'starting from ',
            price: '40000',
        },
    ],
    'fDi - Special reports': [
        {
            format: 'fDi Special Report',
            price: '36000',
        },
    ],
}

const currencies = [
    {
        label: 'GBP £',
        sign: '£',
        value: 1,
    },
    {
        label: 'USD $',
        sign: '$',
        value: 1.55,
    },
    {
        label: 'EUR €',
        sign: '€',
        value: 1.3664,
    },
]

const Rates = () => {
    const [activeCurrency, setActiveCurrency] = useState(0)

    const priceWithCurrency = (price: number) => {
        return (
            currencies[activeCurrency].sign +
            Math.round(price * currencies[activeCurrency].value)
        )
    }

    return (
        <>
            <div className='rates-currency-switcher'>
                <span className='rates-currency-switcher__label'>
                    Currency:{' '}
                </span>
                {currencies.map((currency, index) => (
                    <span
                        key={index}
                        className={`rates-currency-switcher__currency ${activeCurrency === index ? 'active' : ''}`}
                        onClick={() => setActiveCurrency(index)}
                    >
                        {currency.label}
                    </span>
                ))}
            </div>
            {Object.keys(ratesData).map((key, index) => (
                <>
                    <h3 className='rates-heading'>{key}</h3>
                    <table key={index} className='rates-table'>
                        <thead>
                            <tr>
                                <th>Format</th>
                                <th>Price {currencies[activeCurrency].sign}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                            {ratesData[key].map((rate: any, index: number) => (
                                <tr key={index}>
                                    <td>{rate.format}</td>
                                    <td>
                                        {rate.price_prefix}
                                        {priceWithCurrency(rate.price)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ))}
        </>
    )
}

export default Rates
