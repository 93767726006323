import React from 'react'

export interface RadioGroupProps {
    name: string
    label: string
    promptText?: string
    errorText?: boolean | string
    children: React.ReactNode
    error?: boolean
}

const RadioGroup: React.FC<RadioGroupProps> = ({
    name,
    promptText = '',
    label,
    errorText,
    children,
    error = false,
}) => {
    const wrapperClasses = ['o-forms', error ? 'o-forms--error' : '']
        .filter(Boolean)
        .join(' ')

    return (
        <fieldset className={wrapperClasses}>
            <legend
                aria-labelledby={promptText ? `${name}-info` : undefined}
                className='o-forms__label o-form__radio-label'
            >
                {label}
            </legend>
            {promptText && (
                <div id={`${name}-info`} className='o-forms__additional-info'>
                    {promptText}
                </div>
            )}
            <div className='o-forms__group o-forms__group--inline-together'>
                {children}
            </div>
            {errorText && (
                <div className='o-forms__errortext' aria-live='assertive'>
                    {typeof errorText === 'string' && errorText}
                </div>
            )}
        </fieldset>
    )
}

export default RadioGroup
