import React, { useEffect } from 'react'

import { parseCookies } from 'nookies'
import PropTypes from 'prop-types'

import { COOKIE_NAMES } from '../../../assets/constants'
import hasConsent from '../../utils/cookies/hasConsent'

interface LeadForensicsScriptProps {
    onEffectComplete: () => void
}

const LeadForensicsScript = ({
    onEffectComplete,
}: LeadForensicsScriptProps) => {
    useEffect(() => {
        const cookies = parseCookies()

        if (hasConsent(cookies[COOKIE_NAMES.FTConsent])) {
            const script = document.createElement('script')
            script.src = 'https://secure.hall3hook.com/js/198846.js'

            const noscript = document.createElement('noscript')
            const img = document.createElement('img')
            img.src = 'https://secure.hall3hook.com/198846.png'
            img.style.display = 'none'

            noscript.appendChild(img)
            document.head.appendChild(script)
            document.head.appendChild(noscript)
            if (onEffectComplete) onEffectComplete()
        }
    })

    return <></>
}

LeadForensicsScript.propTypes = {
    onEffectComplete: PropTypes.func,
}

LeadForensicsScript.defaultProps = {
    onEffectComplete: () => {},
}

export default LeadForensicsScript
