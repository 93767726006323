import gql from 'graphql-tag'

const queries = {
    submitProductForm: gql`
        mutation submitForm(
            $formType: String!
            $payload: ProductForm!
            $salesForceEnquirySchemaType: String
        ) {
            submitProductForm(
                formType: $formType
                payload: $payload
                salesForceEnquirySchemaType: $salesForceEnquirySchemaType
            ) {
                success
                errors {
                    name
                    msg
                }
            }
        }
    `,
    submitContactUsForm: gql`
        mutation submitForm($formType: String!, $payload: ContactUsForm!) {
            submitContactUsForm(formType: $formType, payload: $payload) {
                success
                errors {
                    name
                    msg
                }
            }
        }
    `,
    submitNewsletterSignupForm: gql`
        mutation submitForm($payload: NewsletterForm!) {
            submitNewsletterSignupForm(payload: $payload) {
                success
                errors {
                    name
                    msg
                }
            }
        }
    `,
    submitGISPlanningForm: gql`
        mutation submitForm(
            $payload: GISPlanningForm!
            $salesForceEnquirySchemaType: String
        ) {
            submitGISPlanningForm(
                payload: $payload
                salesForceEnquirySchemaType: $salesForceEnquirySchemaType
            ) {
                success
                errors {
                    name
                    msg
                }
            }
        }
    `,
}

export default queries
