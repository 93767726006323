import React from 'react'

import PropTypes from 'prop-types'

export interface SectionTitleProps {
    titleContent: string | React.ReactNode
    colSpan?: string
    noBleed?: boolean
}

const SectionTitle = (props: SectionTitleProps) => {
    return (
        <div
            className='section__title--row'
            data-o-grid-colspan={props.colSpan ? props.colSpan : '12'}
        >
            <div className='section__title'>
                <h4 className='section__title__heading'>
                    {props.titleContent}
                </h4>
            </div>
        </div>
    )
}

SectionTitle.propTypes = {
    titleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    noBleed: PropTypes.bool,
}

SectionTitle.defaultProps = {
    noBleed: false,
}

export default SectionTitle
