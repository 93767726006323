import React from 'react'

const Contacts = () => (
    <div className='contacts-container'>
        <div className='contacts-container__contact'>
            <div className='contacts-container__contact__title'>
                Production & Delivery Contacts
            </div>
            <div className='contacts-container__contact__content'>
                Please deliver your copy and creative 21 days prior to the
                publication date or as advised by your FT representative. Send
                your copy and creative to{' '}
                <a className='mt-1' href='mailto:sophie.horton@ft.com'>
                    sophie.horton@ft.com
                </a>
            </div>
        </div>
        <div className='contacts-container__contact'>
            <div className='contacts-container__contact__title'>
                Print Requirements
            </div>
            <div className='contacts-container__contact__content'>
                All adverts will need to be supplied as high resolution PDF
                files and must include trim marks set to trim size of
                publication. Crop marks should be positioned outside the bleed
                and printed area. Please download full specifications.
            </div>
        </div>
        <div className='contacts-container__contact'>
            <div className='contacts-container__contact__title'>Contacts</div>
            <div className='contacts-container__contact__content'>
                <strong>Advertising Contacts</strong>
                <strong>Paul Holman</strong>
                <p>Head of fDi Intelligence Advertising | The FT Group</p>
                <a className='mt-1' href='mailto:paul.holman@ft.com'>
                    paul.holman@ft.com
                </a>
                <a className='mt-1' href='tel:+44 (0)7999 407 760'>
                    +44 (0)7999 407 760
                </a>
            </div>
        </div>
    </div>
)

export default Contacts
