import React from 'react'

import NavList from './NavList'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrimaryNav = ({ level = 1, items }: any) => {
    return (
        <nav
            className='o-header-services__primary-nav o-header-services__primary-nav--open'
            aria-label='primary'
            aria-hidden='false'
        >
            <NavList items={items} level={level} />
        </nav>
    )
}

export default PrimaryNav
