import React, { useRef, useEffect } from 'react'

import NavItem from './NavItem'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavList = (props: any) => {
    const listRef = useRef()

    useEffect(() => {
        /**
         * Observes aria-hidden attribute mutation on list and sets/removes hidden attribute accordingly.
         */
        function mutationObserverCallback(mutationList: MutationRecord[]) {
            const ariaHiddenMutation = mutationList
                .filter(isAriaHiddenMutation)
                .pop()

            if (ariaHiddenMutation) {
                const target = ariaHiddenMutation.target as Element
                const ariaHiddenValue = target.getAttribute('aria-hidden')

                if (ariaHiddenValue === 'true') {
                    target.setAttribute('hidden', '')
                } else {
                    target.removeAttribute('hidden')
                }
            }

            function isAriaHiddenMutation(mutation: MutationRecord) {
                return (
                    mutation.type === 'attributes' &&
                    mutation.attributeName === 'aria-hidden'
                )
            }
        }

        let mutationObserver = null
        try {
            mutationObserver = new MutationObserver(mutationObserverCallback)
        } catch (e) {
            // E.g. Node environment (tests running)
            mutationObserver = null
        }

        if (mutationObserver && listRef.current) {
            mutationObserver.observe(listRef.current, { attributes: true })
        }

        return () => {
            if (mutationObserver) {
                mutationObserver.disconnect()
            }
        }
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getListAttributes = (): any => {
        return props.level === 1
            ? {
                  className: 'o-header-services__primary-nav-list',
              }
            : {
                  className: 'dropdown-itemlist',
                  'data-o-header-services-level': props.level,
                  'aria-hidden': 'true',
                  hidden: true,
                  ref: listRef,
              }
    }

    const listAttributes = getListAttributes()

    return (
        <ul {...listAttributes}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {props.items.map((item: any, index: number) => (
                <NavItem key={index} item={item} level={props.level} />
            ))}
        </ul>
    )
}

export default NavList
