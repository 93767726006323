import React from 'react'

const HeaderAdvert = () => {
    return (
        <div className='leaderboard__container'>
            <div className='leaderboard__advert'>
                <div
                    className='o-ads o-ads--center o-ads--placeholder'
                    data-o-ads-name='top'
                    data-o-ads-targeting='pos=top'
                    aria-hidden='true'
                />
            </div>
        </div>
    )
}

export default HeaderAdvert
