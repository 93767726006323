import React, { useContext } from 'react'
import LoggedInContext from '../../context/LoggedInContext'
import { useFeatureFlags } from '../../provider/FeatureFlagsProvider'
import {
    PROFILE_URL,
    REGISTER_URL,
    LOGIN_URL,
    LOGOUT_URL,
} from '../../../assets/constants'

import UserIcon from './UserIcon'

const LoginMenu = () => {
    const { isLoggedIn } = useContext(LoggedInContext)
    const { isFeatureEnabled } = useFeatureFlags()

    return (
        <div className='login-menu' data-o-grid-colspan='1 M4 Mpush4 L3 Lpush3'>
            <ul className='login-menu__link-container login-menu__list'>
                <li className='login-menu__link--contact login-menu__list-element'>
                    <a
                        data-trackable='Contact Link'
                        data-o-tracking-skip-queue='true'
                        className='login-menu__link login-menu__separator--right'
                        href={`/form/contact-us/${encodeURI('General Enquiry')}`}
                    >
                        Contact us
                    </a>
                </li>
                <li className='login-menu__list-element'>
                    <a
                        data-trackable='Advertise with us'
                        data-o-tracking-skip-queue='true'
                        className='login-menu__link login-menu__separator--right login-menu__separator--left-space'
                        href={`/products-and-services/advertising-opportunities`}
                    >
                        Advertise with us
                    </a>
                </li>
                <li className='login-menu__list-element'>
                    <a
                        data-trackable='Newsletter'
                        data-o-tracking-skip-queue='true'
                        className='login-menu__link login-menu__separator--left-space'
                        href={`/newsletter-signup`}
                    >
                        Newsletter signup
                    </a>
                </li>
                {isFeatureEnabled('isRegistrationsEnabled') && (
                    <li className='login-menu__list-element'>
                        {isLoggedIn ? (
                            <>
                                <a
                                    className='login-menu__link login-menu__separator--right login-menu__separator--left-space'
                                    href={PROFILE_URL}
                                >
                                    My account
                                </a>
                                <a
                                    className='login-menu__link login-menu__separator--left-space'
                                    onClick={() => {
                                        window.dataLayer.push({
                                            event: 'analytics_event',
                                            event_payload: {
                                                schema: 'iglu:com.financialtimes/login/jsonschema/1-0-2',
                                                data: {
                                                    category: 'Login',
                                                    action: 'Logout',
                                                    authentication_type:
                                                        'auth0',
                                                },
                                            },
                                        })
                                    }}
                                    href={LOGOUT_URL}
                                >
                                    Log out
                                </a>
                            </>
                        ) : (
                            <>
                                <a
                                    data-trackable='Log-in Link'
                                    data-o-tracking-skip-queue='true'
                                    className='login-menu__link login-menu__separator--left-space'
                                    href={LOGIN_URL}
                                >
                                    Log in
                                </a>{' '}
                                or&nbsp;
                                <a
                                    data-trackable='Register Link'
                                    data-o-tracking-skip-queue='true'
                                    className='login-menu__link'
                                    href={REGISTER_URL}
                                >
                                    Register
                                </a>
                            </>
                        )}
                    </li>
                )}
            </ul>
            {isFeatureEnabled('isRegistrationsEnabled') && <UserIcon />}
        </div>
    )
}

export default LoginMenu
