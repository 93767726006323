import React from 'react'
import { FieldProps, FormikProps } from 'formik'
import OFormsWrapper, {
    OFormsWrapperProps,
} from '../o-forms-wrapper/OFormsWrapper'

export interface TextareaProps {
    label: string
    required?: boolean
    errorText?: boolean | string
    optional?: boolean
    promptText?: string
    disabled?: boolean
    children?: React.ReactNode
    wide?: boolean
    field: FieldProps['field']
    form?: FormikProps<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Textarea: React.FC<TextareaProps> = ({
    label,
    errorText = '',
    required = false,
    optional = false,
    promptText = '',
    wide = false,
    children,
    field,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    form,
    ...otherProps
}) => {
    const name = field.name

    const wrapperProps = {
        name,
        label,
        errorText,
        optional,
        promptText,
        wide,
        required,
        error: form?.errors[name] || false,
    }

    return (
        <OFormsWrapper {...(wrapperProps as OFormsWrapperProps)}>
            <textarea
                id={name}
                className='o-forms__textarea'
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                required={required}
                {...otherProps}
            >
                {children}
            </textarea>
        </OFormsWrapper>
    )
}

export default Textarea
