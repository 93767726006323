export const jobFunction = [
    'CEO / President',
    'Other C Level (i.e. CFO, COO, CIO, CMO)',
    'Executive Managment (i.e. EVP, SVP, MD)',
    'Chairperson',
    'VP / Director',
    'Senior Manager / Department Head',
    'Manager / Supervisor',
    'Academic / Lecturer / Student',
    'Associate',
    'Administration',
    'Analyst / Researcher / Economist',
    'Broker / Trader / Advisor',
    'Commercial',
    'Consul / Ambassador',
    'Consultant',
    'Diplomat / Government Official',
    'ESG / Sustainability',
    'Finance',
    'Government Minister / Politician',
    'HR',
    'Innovation / Transformation',
    'International Organisation Official',
    'Investment Attraction / Economic Developer',
    'Investor Relations',
    'Legal / Compliance',
    'Library / Information Services',
    'Marketing / PR / Comms',
    'Media / Journalist',
    'Operations',
    'Owner / Partner / Founder',
    'Risk',
    'Secretary / Treasurer',
    'Strategy',
    'Technology',
    'Other (please state)',
]
