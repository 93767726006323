import React, { useState } from 'react'

import { MobileSearchProps } from '../../../types'
import { fireEvent } from '../../tracking/Tracking'
import { OVERLAY_CLOSE } from '../../../assets/constants'

const MobileSearch = ({ closeSearch }: MobileSearchProps) => {
    const [searchValue, setSearchValue] = useState('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        fireEvent(
            {
                category: 'cta',
                action: 'search',
                value: 'submit',
                'data-trackable': searchValue,
            },
            true
        )
    }

    return (
        <div className='mobile-search-container'>
            <div className='o-grid-row mobile-search-container__controls'>
                <div data-o-grid-colspan='6' className='search-label-container'>
                    <span className='search-label'>
                        Search fDi Intelligence
                    </span>
                </div>
                <div data-o-grid-colspan='6' className='close-button-container'>
                    <button className='close-button' onClick={closeSearch}>
                        <span>{OVERLAY_CLOSE}</span>
                        <i className='jsx-498761705 icon o-icons-icon o-icons-icon--cross' />
                    </button>
                </div>
            </div>
            <div className='o-grid-row mobile-search-container__form'>
                <div className='mobile-search' data-o-grid-colspan='12'>
                    <form
                        onSubmit={handleSubmit}
                        action='https://www.fdiintelligence.com/content/search'
                        method='GET'
                        className='o-forms'
                        data-o-component='o-forms'
                    >
                        <input
                            type='text'
                            name='SearchText'
                            autoFocus
                            placeholder='Search locations, sectors, topics'
                            value={searchValue}
                            onChange={handleChange}
                            data-trackable='Search Box'
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MobileSearch
