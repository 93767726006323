import React, { useEffect, useContext, useRef, FC } from 'react'
import { Formik, FastField as Field } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { countries as countryOptions } from '../form/countries'
import { Input, Select, RadioButton, RadioGroup } from '../form/elements'
import { jobFunction as jobFunctionOptions } from '../form/jobFunction'
import { orgTypeOptions } from '../form/orgTypes'
import useSubmitForm from '../hooks/useSubmitForm'

import { initTracking, fireEvent } from '../tracking/Tracking'

import queries from '../../queries/mutations'

import PageTitle, { TITLE_MODIFIERS } from '../page-title/PageTitle'
import SectionTitle from '../section-title/SectionTitle'
import StatusMessage from '../status-message/StatusMessage'
import TrackingContext from '../context/TrackingContext'
import { formTracking } from '../dataLayer'

import {
    COMMON_STRINGS,
    YES,
    NO,
    NEWSLETTER_SIGNUP_FORMTYPE,
} from '../../assets/constants'
import { formattedStringWithLinks } from '../utils/helpers'

export interface TrackingData {
    url: string
    formType: string
    referrer?: string
}

export interface HandleSubmitProps {
    setSubmitting: (isSubmitting: boolean) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setStatus: (status: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setErrors: (errors: any) => void
}

interface NewsletterSignupProps {
    url: string
    referrer: string
    reCaptchaKey: string
    name: string
    apolloServerUrl: string
}

export interface NewsletterSignupValues {
    name_first: string
    name_last: string
    email: string
    country_name: string
    organisation_name_consolidated: string
    organisation_type: string
    job_title: string
    job_function: string
    consent_fdi_invites_and_offers_by_email: string
    recaptcha: string
}

const NewsletterSignupComponent: FC<NewsletterSignupProps> = ({
    url,
    referrer,
    reCaptchaKey,
    name,
    apolloServerUrl,
}) => {
    const form = useRef<HTMLFormElement>(null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { events, setEvents } = useContext(TrackingContext)
    const trackingData: TrackingData & Record<string, string> = {
        url,
        formType: NEWSLETTER_SIGNUP_FORMTYPE,
    }

    useEffect(() => {
        initTracking('demo_form')

        const data = { ...trackingData }

        if (referrer) {
            data.referrer = referrer
        }

        setEvents([
            ...events,
            {
                type: 'add',
                category: 'form',
                actionType: 'landing',
                data,
            },
        ])

        // Initialses the form with o-forms
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const oForms = require('@financial-times/o-forms/browser')
        new oForms(form.current, { applyValidState: true })
    }, [])

    useEffect(() => {
        if (events.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            events.forEach((event: any) => {
                const detail = {
                    category: event.category,
                    action: event.actionType,
                    ...event.data,
                }
                fireEvent(detail, true)
            })

            setEvents([])
        }
    }, [events])
    const [submitForm] = useSubmitForm(apolloServerUrl)
    const handleSubmit = async (
        values: NewsletterSignupValues,
        { setSubmitting, setStatus, setErrors }: HandleSubmitProps
    ) => {
        const variables = {
            formType: NEWSLETTER_SIGNUP_FORMTYPE,
            payload: values,
        }

        const query = queries.submitNewsletterSignupForm

        const [success, errors] = await submitForm(
            'submitNewsletterSignupForm',
            query,
            variables,
            trackingData
        )

        setSubmitting(false)

        if (errors) {
            setErrors(errors)
        } else {
            setStatus({ success })
            window.scrollTo(0, 0)
        }

        formTracking(!!errors, 'fdi_newsletter_signup_form', {
            email: values.email,
            service: 'newsletter',
        })
    }

    const initialValues = {
        name_first: '',
        name_last: '',
        email: '',
        country_name: '',
        organisation_name_consolidated: '',
        organisation_type: '',
        job_title: '',
        job_function: '',
        consent_fdi_invites_and_offers_by_email: YES,
        recaptcha: reCaptchaKey,
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({
                values,
                isSubmitting,
                errors,
                setFieldValue,
                touched,
                handleSubmit,
                status,
            }) => {
                return (
                    <>
                        {status && status.success !== undefined && (
                            <StatusMessage
                                status={status}
                                title='Thank you for signing-up'
                                message='Please look out for the next edition of the fDi Intelligence newsletter in your inbox soon.'
                                showContactUs
                            />
                        )}
                        {!status && (
                            <>
                                <PageTitle
                                    title={name}
                                    titleModifier={
                                        TITLE_MODIFIERS.SMALLER_FONT_SIZE
                                    }
                                />
                                <div className='o-grid-container'>
                                    <div className='o-grid-row'>
                                        <div
                                            data-o-grid-colspan='12'
                                            className='field-required-text center-text'
                                        >
                                            <p>
                                                fDi Intelligence email
                                                newsletters provide the global
                                                FDI community with the latest
                                                news, data trends, analysis,
                                                location rankings, multimedia
                                                content and more.
                                            </p>
                                            <p>
                                                If you would like to receive our
                                                free email newsletters, please
                                                complete the form below.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='o-grid-container form-container'>
                                    <div className='o-grid-row'>
                                        <div data-o-grid-colspan='12 center M6 L4 Loffset4'>
                                            <form
                                                onSubmit={handleSubmit}
                                                ref={form}
                                            >
                                                <div className='o-forms'>
                                                    <h2 className='o-forms__headline'>
                                                        {
                                                            COMMON_STRINGS.FORMS
                                                                .YOUR_DETAILS
                                                        }
                                                    </h2>
                                                </div>

                                                <Field
                                                    component={Input}
                                                    name='name_first'
                                                    label='First name'
                                                    type='text'
                                                    errorText
                                                    required
                                                    minLength='2'
                                                />

                                                <Field
                                                    component={Input}
                                                    name='name_last'
                                                    label='Last name'
                                                    type='text'
                                                    errorText
                                                    required
                                                    minLength='2'
                                                />

                                                <Field
                                                    component={Input}
                                                    name='email'
                                                    label='Email'
                                                    type='email'
                                                    errorText={
                                                        COMMON_STRINGS.FORMS
                                                            .FIELDS.EMAIL.ERROR
                                                    }
                                                    required
                                                    minLength='5'
                                                />

                                                <Field
                                                    component={Select}
                                                    name='country_name'
                                                    label='Country'
                                                    options={countryOptions}
                                                    errorText
                                                    required
                                                />

                                                <Field
                                                    component={Input}
                                                    name='organisation_name_consolidated'
                                                    label='Organisation name'
                                                    type='text'
                                                    required
                                                    errorText
                                                    minLength='2'
                                                />

                                                <Field
                                                    component={Select}
                                                    name='organisation_type'
                                                    label='Organisation type'
                                                    options={orgTypeOptions}
                                                    errorText={
                                                        COMMON_STRINGS.FORMS
                                                            .FIELDS
                                                            .ORGANISATION_TYPE
                                                            .ERROR
                                                    }
                                                    required
                                                />

                                                <Field
                                                    component={Input}
                                                    name='job_title'
                                                    label='Job title'
                                                    type='text'
                                                    errorText
                                                    required
                                                    minLength='2'
                                                />

                                                <Field
                                                    component={Select}
                                                    name='job_function'
                                                    label='Job function'
                                                    options={jobFunctionOptions}
                                                    errorText={
                                                        COMMON_STRINGS.FORMS
                                                            .FIELDS.JOB_FUNCTION
                                                            .ERROR
                                                    }
                                                    required
                                                />

                                                <p>
                                                    By submitting this form you
                                                    agree to receive fDi
                                                    Intelligence email
                                                    newsletters. We will start
                                                    sending them to you straight
                                                    away. You can unsubscribe at
                                                    any time using the
                                                    unsubscribe link which you
                                                    will find on all of our
                                                    newsletters.
                                                </p>
                                                <div className='o-forms alert-options o-grid-row--compact'>
                                                    <SectionTitle
                                                        titleContent={
                                                            'Invites and Offers from FT Locations (Email)'
                                                        }
                                                    />
                                                    <p
                                                        data-o-grid-colspan='12'
                                                        dangerouslySetInnerHTML={formattedStringWithLinks(
                                                            COMMON_STRINGS.FORMS
                                                                .PERSONALISED_OFFERS_BODY_NEWSLETTER,
                                                            'FT Locations by email.|https://www.fdiinsights.com/about#fDi-Intelligence-portfolios'
                                                        )}
                                                    />
                                                </div>

                                                <RadioGroup
                                                    name='consent_fdi_invites_and_offers_by_email'
                                                    label='By email'
                                                >
                                                    <>
                                                        <Field
                                                            component={
                                                                RadioButton
                                                            }
                                                            name='consent_fdi_invites_and_offers_by_email'
                                                            label='Yes'
                                                            value={YES}
                                                        />
                                                        <Field
                                                            component={
                                                                RadioButton
                                                            }
                                                            name='consent_fdi_invites_and_offers_by_email'
                                                            label='No'
                                                            value={NO}
                                                        />
                                                    </>
                                                </RadioGroup>

                                                <ReCAPTCHA
                                                    style={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '12px',
                                                    }}
                                                    sitekey={reCaptchaKey}
                                                    onChange={response => {
                                                        setFieldValue(
                                                            'recaptcha',
                                                            response
                                                        )
                                                    }}
                                                    className='o-forms'
                                                />
                                                {errors.recaptcha &&
                                                    touched.recaptcha && (
                                                        <p>
                                                            {errors.recaptcha}
                                                        </p>
                                                    )}

                                                <div className='o-forms submit-container'>
                                                    <input
                                                        type='submit'
                                                        onClick={() => {
                                                            if (
                                                                !form.current?.checkValidity()
                                                            ) {
                                                                formTracking(
                                                                    true,
                                                                    'fdi_contact_us_form',
                                                                    {
                                                                        email: values.email,
                                                                        service:
                                                                            'newsletter',
                                                                    }
                                                                )
                                                            }
                                                        }}
                                                        value='Submit'
                                                        className='o-forms__submit o-buttons o-buttons--primary o-buttons--mono o-buttons--big'
                                                        disabled={
                                                            !values.recaptcha ||
                                                            isSubmitting
                                                        }
                                                    />
                                                </div>
                                                <p>
                                                    For more information about
                                                    how we use your data, please
                                                    refer to our{' '}
                                                    <a
                                                        href='https://help.ft.com/legal-privacy/cookies/'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        Cookie Policy
                                                    </a>{' '}
                                                    and the relevant privacy
                                                    policies:{' '}
                                                    <a
                                                        href='https://help.ft.com/legal-privacy/privacy-policy/'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        FT Privacy Policy
                                                    </a>
                                                    ,{' '}
                                                    <a
                                                        href='https://newproducts.fdiintelligence.com/privacy-policy/'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        FT Overseas Privacy
                                                        Policy
                                                    </a>{' '}
                                                    and{' '}
                                                    <a
                                                        href='https://www.gisplanning.com/legal/privacy'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        GIS Planning Privacy
                                                        Policy
                                                    </a>
                                                    .
                                                </p>
                                                <p>
                                                    Have a question?{' '}
                                                    <a
                                                        href='https://www.fdiintelligence.com/form/contact-us/Customer%20support'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        Click here to contact us
                                                    </a>
                                                    .
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )
            }}
        </Formik>
    )
}

export default NewsletterSignupComponent
