import React, { useRef } from 'react'

const BurgerMenuControls = () => {
    const burgerMenu = useRef<HTMLDivElement>(null)

    const onMenuTextClick = () => {
        if (burgerMenu.current) {
            const hamburgerIcon: HTMLButtonElement | null =
                burgerMenu.current.querySelector(
                    '.o-header-services__hamburger-icon'
                )
            if (hamburgerIcon) {
                hamburgerIcon.click()
            }
        }
    }

    return (
        <div
            className='o-header-services__hamburger-container'
            data-o-grid-colspan='3 M1'
            ref={burgerMenu}
        >
            <button className='o-header-services__hamburger o-header-services__hamburger-icon'>
                <mark className='menu-text' onClick={onMenuTextClick}>
                    Menu
                </mark>
                <span style={{ display: 'none' }} />
            </button>
        </div>
    )
}

export default BurgerMenuControls
