import React from 'react'

import PropTypes from 'prop-types'

import { TITLES } from '../../assets/constants'

export const TITLE_MODIFIERS = {
    SMALLER_FONT_SIZE: 'page-title__title--smaller-font-size',
    SMALLER_MARGIN: 'page-title__title--smaller-margin',
    BIG_BOTTOM_MARGIN_ON_L: 'page-title__title--big-bottom-margin-on-l',
    NO_BOTTOM_MARGIN_ON_L: 'page-title__title--no-bottom-margin-on-l',
}

export interface PageTitleProps {
    children?: React.ReactNode
    title: string
    titleModifier: string
}

const PageTitle = ({ children, title, titleModifier }: PageTitleProps) => {
    const parentClassName = getParentClassNameByTitle(title)

    const titleClassName = [
        'page-title__title',
        ...(titleModifier ? [titleModifier] : []),
    ].join(' ')

    return (
        <div className={parentClassName}>
            <div className='o-grid-container'>
                <div className='o-grid-row'>
                    <div data-o-grid-colspan='12'>
                        <h1 className={titleClassName}>{title}</h1>
                    </div>
                </div>
                {children && children}
            </div>
        </div>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    titleModifier: PropTypes.string,
    children: PropTypes.node,
}

PageTitle.defaultProps = {
    titleModifier: '',
}

export default PageTitle

function getParentClassNameByTitle(title: string) {
    const baseClassName = 'page-title'

    switch (title) {
        case TITLES.FDI_PODCAST:
        case TITLES.RANKINGS_AND_AWARDS:
        case TITLES.OPINION:
        case TITLES.INSIDE_FDI:
        case TITLES.THE_GLOBAL_LAWYER:
            return getClassNameWithModifier('editorial-light')
        case TITLES.FREE_ZONE_FOCUS:
        case TITLES.VIEW_FROM:
            return getClassNameWithModifier('editorial')
        case TITLES.VIEW_FROM_AMERICAS:
            return getClassNameWithModifier('americas')
        case TITLES.VIEW_FROM_ASIA_PACIFIC:
            return getClassNameWithModifier('asia-pacific')
        case TITLES.VIEW_FROM_EUROPE:
            return getClassNameWithModifier('europe')
        case TITLES.VIEW_FROM_MIDDLE_EAST_AFRICA:
            return getClassNameWithModifier('middle-east-africa')
        case TITLES.COMMERCIAL:
            return getClassNameWithModifier('commercial')
        default:
            return baseClassName
    }

    function getClassNameWithModifier(modifier: string) {
        return `${baseClassName} ${baseClassName}--${modifier}`
    }
}
